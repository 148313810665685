<template>
  <s-checkbox-group
    v-model="value"
    :class="[props.class, { 's-checkbox-group--single': isSingle }]"
    :name="fieldName"
    :isDisabled="disabled"
    :size="size"
    @update:modelValue="handleModelUpdate"
  >
    <slot v-if="$slots['extension-option']" name="extension-option"></slot>
    <template v-for="(opt, index) in options">
      <slot
        v-if="hasSlot(`option-${index}`)"
        :name="`option-${index}`"
        :label="opt.label"
        :value="opt.value"
        :index="index"
      ></slot>
      <slot
        v-else-if="hasSlot(`default`)"
        :name="'default'"
        :label="opt.label"
        :value="opt.value"
        :index="index"
      ></slot>
      <s-checkbox
        v-else
        :key="index"
        :value="opt.value"
        v-bind="optionProps"
        :isDisabled="opt.isDisabled"
        class="flex items-center [&>div>label]:overflow-hidden"
      >
        <s-tooltip
          v-if="showTooltip"
          arrow
          :content="`<p class='break-all'>${notTranslate ? opt.label : $t(opt.label)}</p>`"
          :duration="0"
          :distance="4"
          useFlip
          flipOnUpdate
          placement="bottom-start"
          trigger="mouseenter focus"
          :theme="'studio-tooltip'"
          :zIndex="600"
          :allowHTML="true"
          :class="'truncate'"
        >
          <template #target>
            <span class="truncate tracking-[0] block text-on-surface-elevation-2">{{
              notTranslate ? opt.label : $t(opt.label)
            }}</span>
          </template>
        </s-tooltip>
        <span v-else class="text-on-surface-elevation-2">{{
          notTranslate ? opt.label : $t(opt.label)
        }}</span>
      </s-checkbox>
    </template>
  </s-checkbox-group>

  <st-error-message :name="fieldName" :showError="showFieldError" />
</template>

<script setup lang="ts">
import { computed, ref, toRefs, useSlots, watch } from 'vue';

import StErrorMessage from '@/components/validation/st-error-message.vue';
import useValidation from '@/composables/useValidation';
import type { CheckboxGroupProps } from '@/types/common/form.type';

const slots = useSlots();

const props = withDefaults(defineProps<CheckboxGroupProps>(), {
  size: 'md',
  disabled: false,
  notTranslate: false,
  showTooltip: false
});

const { rules } = toRefs(props);

const emit = defineEmits<{
  'update:modelValue': [v: string[]];
}>();

const value = ref(props.modelValue);

const hasSlot = (name: string) => !!slots[name];

const setFieldValue = ref<(value: string[]) => void>();

const fieldName = computed<string>(() => props.name ?? '');
const showFieldError = ref(false);

if (fieldName.value) {
  const { setValue, showError } = useValidation<string[]>({
    fieldName: fieldName.value,
    rules,
    value
  });
  setFieldValue.value = setValue;

  watch(
    () => showError.value,
    (v: boolean) => {
      showFieldError.value = v;
    }
  );
}

watch(
  () => props.modelValue,
  (v?: string[] | number[]) => {
    value.value = v;
  }
);

const handleModelUpdate = (e: string[]) => {
  if (setFieldValue.value) {
    setFieldValue.value(e);
  }
  emit('update:modelValue', e);
};
</script>
